
import { defineComponent } from 'vue';
import ConfirmAction from '@/components/ConfirmAction.vue';
import { FreeAgentInterface, fetchFreeAgents } from '@/services/freeagents'
import { TeamInterface } from '@/services/teams';
import { store } from '@/store/store';
import { ACTION } from '@/store/actions';
import { MUTATION } from '@/store/mutations';
import TeamLabel from '@/components/TeamLabel.vue';
import {
    GENDER_TYPE, 
    MAX_CHAR_FREEAGENT_ADDITIONAL_INFO_INPUT, 
} from '@/constants';
import { validateEmail } from '@/utils';

export default defineComponent({
    name: 'FreeAgents',
    components: { TeamLabel, ConfirmAction },
    data() {
        return { 
            filterSetName: '__freeagents__',
            // Team selector
            friendsWith: [] as string[],

            // Free Agent Add (Admin only)
            newFreeAgentName: '',
            newFreeAgentGender: '',
            newFreeAgentCell: '',
            newlyAddedFreeAgentName: '',
            newFreeAgentEmail: '',
            newFreeAgentTeams: '',
            newFreeAgentInfo: '',
            
            // Add me on the free agent list (anybody)
            addMeName: '',
            addMeGender: '',
            addMeInfo: '',
            addMeEmail: '',
            addMePhoneNumber: '',

            addMeNameError: false,
            addMeGenderError: false,
            addMeEmailError: false,
            addMePhoneError: false,
            addMeInfoError: false,
        };
    },
    computed: {
        freeAgents(): FreeAgentInterface[] {
            return store.state.freeAgents;
        },
        isAdmin(): boolean {
            return store.getters.isAdmin;
        },
        canViewFriendsWith(): boolean {
            return store.getters.isAdmin || store.getters.isCaptain;
        },
        freeAgentAddValid(): boolean {
            // Quick validator only for admin free agent adds, to improve
            return this.newFreeAgentName != '' &&
                    Object.values(GENDER_TYPE).indexOf(this.newFreeAgentGender) !== -1 &&
                    (this.newFreeAgentCell != '' || this.newFreeAgentEmail != '');
        },
        sortedTeams(): string[] {
            return store.getters.sortedTeams;
        },
        genderTypes(): string[] {
            return Object.values(GENDER_TYPE);
        },
        // Any site visitors validators
        validName(): boolean {
            const NameRegEx = /^[a-zA-ZéÉèÈêÊçÇÀàÃãÙùÜüÏï._-][a-zA-ZéÉèÈêÊçÇÀàÃãÙùÜüÏï\s._-]*$/;
            return NameRegEx.test(this.addMeName);
        },
        validGender(): boolean {
            return Object.values(GENDER_TYPE).indexOf(this.addMeGender) !== -1
        },
        validEmail(): boolean {
            return validateEmail(this.addMeEmail);
        },
        validPhoneNumber(): boolean {
            const PhoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
            return (this.addMePhoneNumber == "") || PhoneRegEx.test(this.addMePhoneNumber) ;
        },
        validAdditionalInfo(): boolean {
            return this.addMeInfo.length < MAX_CHAR_FREEAGENT_ADDITIONAL_INFO_INPUT;
        },
        validAddRequest(): boolean {
            return this.validName && this.validGender && this.validEmail && this.validAdditionalInfo;
        },
        hasInputErrorName(): boolean {
            return this.addMeNameError;
        },
        hasInputErrorGender(): boolean {
            return this.addMeGenderError;
        },
        hasInputErrorEmail(): boolean {
            return this.addMeEmailError;
        },
        hasInputErrorPhone(): boolean {
            return this.addMePhoneError;
        },
        hasInputErrorInfo(): boolean {
            return this.addMeInfoError;
        },
        addMeTextPlaceholder(): string {
            return (
                `Avez-vous déjà jouer à la balle molle? Dans notre ligue ou `+
                `une autre ligue? \n Comment avez-vous entendu parlé de la `+
                `ligue? \n Positions préférées?`
            );
        },
        hasAgentLibreLink(): boolean {
            return store.getters.hasAgentLibreLink;
        },
        agentLibreLink(): string {
            return store.getters.agentLibreLink;
        }
    },
   async mounted() {
       const freeagents = await fetchFreeAgents();
       store.commit(MUTATION.SET_FREE_AGENTS, freeagents);
   },
    methods: {
        addFreeAgent() {
            if (!this.freeAgentAddValid) return;

            // Read data
            const agent = {
                name  : this.newFreeAgentName,
                gender : this.newFreeAgentGender,
                cell  : this.newFreeAgentCell,
                email : this.newFreeAgentEmail,
                info : this.newFreeAgentInfo,
                knownteams: this.friendsWith,
            };

            // Add player
            store.dispatch(ACTION.ADD_FREE_AGENT, agent);

            // Locally store newlyAddedPlayer for display
            this.newlyAddedFreeAgentName = this.newFreeAgentName;

            // Reset values
            this.newFreeAgentName = '';
            this.newFreeAgentGender = '';
            this.newFreeAgentCell = '';
            this.newFreeAgentEmail =  '';
            this.newFreeAgentInfo = '' ; 
            store.commit(MUTATION.SET_TEAM_NAMES_FILTERS, {filterSet: this.filterSetName, teamNames: []});
            
        },
        removeFreeAgent(agent: FreeAgentInterface) {
            store.dispatch(ACTION.REMOVE_FREE_AGENT, agent);
        },
        lastFreeAgentAdded(agentName: string) {
            return agentName === this.newlyAddedFreeAgentName;
        },
        requestAddFreeAgent(){

            if (!this.validAddRequest) return;

            // Read data
            const addMeData = {
                name  : this.addMeName,
                gender : this.addMeGender,
                cell  : this.addMePhoneNumber,
                email : this.addMeEmail,
                info : this.addMeInfo,
            };

            // Add player
            store.dispatch(ACTION.SEND_ADD_ME_EMAIL, addMeData);

            // Reset values
            this.addMeName = '';
            this.addMeGender = '';
            this.addMePhoneNumber = '';
            this.addMeEmail = '';
            this.addMeInfo = '' ; 

            this.addMeEmailError = false;
        },
        teamInfo(teamName: string): TeamInterface {
            return store.getters.teamInfo(teamName);
        },
        showErrorName() {
            this.addMeNameError = !this.validName;
        },
        showErrorGender() {
            this.addMeGenderError = !this.validGender;
        },
        showErrorEmail() {
            this.addMeEmailError = !this.validEmail;
        },
        showErrorPhone() {
            this.addMePhoneError = !this.validPhoneNumber;
        },
        showErrorInfo() {
            this.addMeInfoError = !this.validAdditionalInfo;
        },
        hasTelephone(agent: FreeAgentInterface) {
            return agent.cell !== undefined;
        },
        hasEmail(agent: FreeAgentInterface) {
            return agent.email !== undefined;
        },
        hasFriends(agent: FreeAgentInterface) {
            return agent.knownteams.length > 0;
        }

    }
});
